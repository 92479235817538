<template>

  <v-app-bar :elevation="8" height="50" style="z-index: 900;">
    <v-container>
      <v-row>
        <v-col>
          <v-app-bar-title style="letter-spacing: 13px; font-size: 1.6em;">RPG Troll</v-app-bar-title>

        </v-col>
      </v-row>
    </v-container>
    <v-spacer></v-spacer>
    <v-img v-if="this.$store.state.User" class="icon-img custom-pointer"
      :src="require('@/assets/OIcon/HistoryIcon.png')" @click="HistoryDrawer = !HistoryDrawer" height="40"
      margin="5,15"></v-img>
  </v-app-bar>

  <v-navigation-drawer expand-on-hover mobile-breakpoint="750" width=350 rail rail-width="55" style="z-index: 800;">
    <v-list>
      <v-divider thickness="3"></v-divider>
      <v-card link @click="this.$router.push({ name: 'WürfeltoolView' })" class="custom-pointer">
        <div style="display: flex; align-items: center; ">
          <img style="margin: 10px; margin-right: 19px;" :src="require('@/assets/OIcon/WuerfelIcon.png')" height="35"
            width="35" />
          <p style="font-size: 1.3em">Würfeltool</p>
        </div>
      </v-card>

      <v-divider thickness="3"></v-divider>

      <v-card link @click="this.$router.push({ name: 'MeineCharaktereView' })" class="custom-pointer"
        :disabled="!this.$store.state.User || !this.$store.state.User.emailVerified">
        <div style="display: flex; align-items: center; ">
          <img style="margin: 10px; margin-right: 19px;" :src="require('@/assets/OIcon/TrollfaceIcon.png')" height="35"
            width="35" />
          <p style="font-size: 1.3em">Meine Charaktere</p>
          <p v-if="!this.$store.state.User" style="font-size: 0.8em; margin-left:40px; max-width:40px;">Login nötig</p>
        </div>
      </v-card>

      <v-divider thickness="3"></v-divider>

      <v-card link @click="this.$router.push({ name: 'GamemasterView' })" class="custom-pointer"
        v-if="this.$store.state.User && this.$store.state.User.uid == 'n3t7E4a3TJfrx2EPoTlqGdS5G9u2'">
        <div style="display: flex; align-items: center; ">
          <img style="margin: 10px; margin-right: 19px;" :src="require('@/assets/OIcon/adminIcon.png')" height="35"
            width="35" />
          <p style="font-size: 1.3em">Spielleiter</p>
        </div>
      </v-card>

      <v-divider thickness="3"></v-divider>

      <v-card link @click="this.$router.push({ name: 'EinstellungenView' })" class="custom-pointer"
        :disabled="!this.$store.state.User">
        <div style="display: flex; align-items: center; ">
          <img style="margin: 10px; margin-right: 19px;" :src="require('@/assets/OIcon/EinstellungenIcon.png')"
            height="35" width="35" />
          <p style="font-size: 1.3em">Einstellungen</p>
          <p v-if="!this.$store.state.User" style="font-size: 0.8em; margin-left:40px; max-width:40px;">Login nötig</p>
        </div>
      </v-card>

      <v-divider thickness="3"></v-divider>

      <v-card link @click="this.$router.push({ name: 'AboutView' })" class="custom-pointer">
        <div style="display: flex; align-items: center; ">
          <img style="margin: 10px; margin-right: 19px;" :src="require('@/assets/OIcon/uberIcon.png')" height="35"
            width="35" />
          <p style="font-size: 1.3em">Über...</p>
        </div>
      </v-card>

      <v-divider thickness="3"></v-divider>

    <v-card link @click="this.$router.push({ name: 'AdminView' })" class="custom-pointer"
      v-if="this.$store.state.User && this.$store.state.User.uid == 'n3t7E4a3TJfrx2EPoTlqGdS5G9u2'">
      <div style="display: flex; align-items: center; ">
        <img style="margin: 10px; margin-right: 19px;" :src="require('@/assets/OIcon/adminIcon.png')" height="35"
          width="35" />
        <p style="font-size: 1.3em">Adminansicht</p>
      </div>
    </v-card>
    </v-list>

    <v-spacer></v-spacer>

    <template v-slot:append>
      <div class="pa-2" style="margin-bottom: 20px; display: flex; flex-direction: column;">
        <v-label style="font-size: 0.8em; font-style: italic;">{{ this.$store.state.LoginText }}</v-label>
        <v-btn class="custom-button" v-if="!this.$store.state.User" @click="this.$router.push({ name: 'LoginView' })">
          Login
        </v-btn>
        <v-btn class="custom-button" v-if="this.$store.state.User" @click="Logout">
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>

  <v-navigation-drawer min-width=400 width=750 location="right" mobile-breakpoint="0" v-model="HistoryDrawer"
    style="z-index: 1050; display: flex; flex-direction: column;">
    <v-form @submit.prevent="sendMessage" style="display: flex; flex-direction: column; height: 100%;">
      <v-container ref="messagesContainer" fluid style="max-height: 100%; overflow-y: auto;">
        <div>
          <v-row>
            <v-col>
              <h2 class="custom-h2">Historie</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card v-for="Nachricht in $store.getters.formatierteNachrichten" :key="Nachricht.id"
                :class="nachrichtKlasse(Nachricht.userid)">
                <v-row>
                  <v-col>
                    <div class="d-flex">
                      <p class="custom-title">{{ Nachricht.name }}</p>
                      <p class="custom-subtitle">{{ Nachricht.formatiertesDatum }}</p>
                    </div>
                  </v-col>
                </v-row>
                <v-card-text class="custom-text" style="white-space: pre-line;">{{ Nachricht.message }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
      
      <v-container>
        <v-row>
          <v-col cols="10">
            <v-text-field label="Nachricht" v-model="Message" />
          </v-col>
          <v-col cols="2">
            <v-img :src="require('@/assets/OIcon/playIcon.png')" alt="Speichern" height="45" class="icon-img"
              @click="sendMessage" />
          </v-col>
        </v-row>
      </v-container>
    </v-form>


  </v-navigation-drawer>
</template>


<script>
import { sendMessageToHistory } from '../composables/useSendHistory.js'
//import { getFirestore, collection, query, where, /*orderBy,*/ onSnapshot } from 'firebase/firestore';

import {
  getAuth, signOut
} from 'firebase/auth'

const auth = getAuth()

export default {
  data: () => {
    return {
      HistoryDrawer: false,
      Message: '',
      Messages: []
    }
  },

  updated() {
    this.$nextTick(() => {
      const container = this.$refs.messagesContainer.$el
      container.scrollTop = container.scrollHeight
    })
  },

  setup() {
    return {
      sendMessageToHistory
    };
  },

  computed: {
    nachrichten() {
      return this.$store.getters.formatierteNachrichten
    }
  },

  watch: {
    nachrichten() {
      const container = this.$refs.messagesContainer.$el
      container.scrollTop = container.scrollHeight
    }
  },

  methods: {
    Logout() {
      signOut(auth)
        .then(() => {
          this.$store.commit('callSnackbar', "Erfolgreich abgemeldet.")
        })
    },

    async sendMessage() {
      if (this.Message) {
        await sendMessageToHistory(this.Message)
        this.Message = ''
        const container = this.$refs.messagesContainer.$el
        container.scrollTop = container.scrollHeight
      }
    },

    nachrichtKlasse(userid) {
      if (this.$store.state.User) {
        return userid === this.$store.state.User.uid ? 'user-message' : 'fremd-message'
      } else {
        return 'user-message'
      }
    },

  }
}
</script>

<style>
.user-message {
  background-color: rgba(0, 175, 0, 0.05);
  color: rgb(241, 255, 240);
  margin-bottom: 25px;
}

.fremd-message {
  background-color: rgba(0, 50, 175, 0.05);
  color: rgba(194, 205, 235, 0.75);
  margin-bottom: 25px;
}

.fremd-message p,
.user-message p {
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 0;
}

.fremd-message .v-card-text {
  text-align: right;
  margin-right: 25px;
}

.custom-title {
  font-size: 0.9em;
  font-weight: bold;
}

.custom-subtitle {
  font-size: 0.8em;
  font-style: italic;
}
</style>
